import React, { useEffect, useRef } from 'react';
import './css/GiftComponent.css';

const GiftComponent = ({ giftMessages, fontSize }) => {
  const chatContainerRef = useRef(null);
  const shouldScrollToBottomRef = useRef(true);

  const isUserAtBottom = () => {
    const container = chatContainerRef.current;
    return container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
  };

  useEffect(() => {
    // Check if the user is at the bottom before updating the messages
    shouldScrollToBottomRef.current = isUserAtBottom();
  }, []);

  useEffect(() => {
    if (shouldScrollToBottomRef.current) {
      const container = chatContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [giftMessages]);

  return (
    <div className='gift-div'>
      <h1>Gifts:</h1>
      <div
        className="gift-container"
        ref={chatContainerRef}
        onScroll={() => { shouldScrollToBottomRef.current = isUserAtBottom(); }}
      >
        {giftMessages.map((messageObj, index) => (
        <div key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
        <strong style={{ fontSize: `${fontSize - 4}px` }}>{messageObj.username}: </strong>
        <span style={{ fontSize: `${fontSize - 4}px` }}>{messageObj.message}</span>
      </div>
        ))}
      </div>
    </div>
  );
};

export default GiftComponent;