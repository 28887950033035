import React from "react";
import people from './Assets/people.svg'
import './css/ViewerCount.css';

function ViewerCount({ currentViewerCount }) {
    const formattedCount = currentViewerCount.toLocaleString();

    return (
        <div className="viewer-comp">
            <img src={people} alt='Viewer Icon' className="viewer-icon" />
            <h2 className="count">{formattedCount}</h2>
        </div>
    );
};

export default ViewerCount;