import React, { useEffect, useRef } from 'react';
import './css/ChatComponent.css';

const ChatComponent = ({ messages, fontSize }) => {
  const chatContainerRef = useRef(null);
  const shouldScrollToBottomRef = useRef(true);

  const isUserAtBottom = () => {
    const container = chatContainerRef.current;
    return container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
  };

  useEffect(() => {
    // Check if the user is at the bottom before updating the messages
    shouldScrollToBottomRef.current = isUserAtBottom();
  }, []);

  useEffect(() => {
    if (shouldScrollToBottomRef.current) {
      const container = chatContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  return (
    <div className='chat-div'>
      <h1>Chat Messages:</h1>
      <div
        className="chat-container"
        ref={chatContainerRef}
        onScroll={() => { shouldScrollToBottomRef.current = isUserAtBottom(); }}
        style={{ overflowy: 'scroll' }}
      >
        {messages.map((messageObj, index) => (
        <div key={index} className={index % 2 === 0 ? 'even' : 'odd'}>
        <strong style={{ fontSize: `${fontSize}px` }}>{messageObj.username} said: </strong>
        <span style={{ fontSize: `${fontSize}px` }}>{messageObj.message}</span>
      </div>
        ))}
      </div>
    </div>
  );
};

export default ChatComponent;