import React, { useState, useEffect } from 'react';
import ChatComponent from "./ChatComponent";
import ViewerCount from "./ViewerCount";
import LikeCount from './LikeCount';
import GiftComponent from './GiftComponent';
import SocialComponent from './SocialComponent';
import DiamondCount from './DiamondCount';
import Lottie from 'lottie-react';
import animationData from './Assets/loading.json';
import './css/App.css';
import kofi from './Assets/kofi.jpg'

function App() {
  const [messages, setMessages] = useState([]);
  const [giftMessages, setGiftMessages] = useState([]);
  const [socialMessages, setSocialMessages] = useState([]);
  const [currentViewerCount, setCurrentViewerCount] = useState(0);
  const [currentLikeCount, setCurrentLikeCount] = useState(0);
  const [streamerName, setStreamerName] = useState('');
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState('');
  const [totalDiamonds, setTotalDiamonds] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [fontSize, setFontSize] = useState(20);

  const increaseFont = () => setFontSize(prevSize => prevSize + 2);
  const decreaseFont = () => setFontSize(prevSize => prevSize - 2);

  useEffect(() => {
    if (ws) {
      ws.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);
        
        if (data.type === 'connectedStatus') {
          // Update connected state
          setIsConnected(data.status);

          // If the status is 'failed', reset it to 'disconnected' after 3 seconds
          if (data.status === 'failed') {
            setTimeout(() => {
              setLoadingStatus(false);
              setIsConnected('');
            }, 3000);
        }}  

        if (data.type === 'chat') {
          // Update state to include received message
          setMessages(prevMessages => [...prevMessages, { username: data.username, message: data.message }]);
        }
        if (data.type === 'viewerCount') {
          // Update state to current viewer count
          setCurrentViewerCount(data.count);
        }
        if (data.type === 'likeCount') {
          // Update state to current like count
          setCurrentLikeCount(data.count);
        }    
        if (data.type === 'gift') {
          let giftMessage;
          let diamondValue = data.value;
          if (data.message === 'in-progress') {
            giftMessage = `is sending ${data.giftName}!`;
          } else {
            giftMessage = `sent ${data.repeatCount} ${data.giftName}!`;
            diamondValue *= data.repeatCount;
            setTotalDiamonds(prevTotal => prevTotal + diamondValue);
          }
          setGiftMessages(prevGiftMessages => [...prevGiftMessages, { username: data.username, message: giftMessage }]);
        }
        if (data.type === 'social') {
          // Update state to include received message
          setSocialMessages(prevSocialMessages => [...prevSocialMessages, { username: data.username, message: data.message }]);
        }
      });
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  const handleConnect = () => {
    setLoadingStatus(true);
    const ws = new WebSocket('wss://www.ttchat.pro:8477');
    ws.addEventListener('open', () => {
      ws.send(JSON.stringify({ type: 'connectStreamer', username: streamerName }));
    });
    setWs(ws);
  };

  return (
    <div className='container' id='test'>
    <div className={`App ${darkMode ? 'dark-mode' : 'light-mode'}`} data-theme={darkMode ? 'dark' : 'light'} >
      <header className="App-header">
        <div className='buttons-div'>
          <div className='mode-toggle-div'>
            <button className='mode-toggle' onClick={() => setDarkMode(prevMode => !prevMode)}>
              Dark Mode
            </button>
          </div>
          <div className='font-buttons-div'>
            <button onClick={decreaseFont} className='font-button' id='-button'>-</button>
            <p className='font-size'>Font Size </p>
            <button onClick={increaseFont} className='font-button'>+</button>
          </div>
        </div>
        <div className='kofi-div'>
          <a href="https://ko-fi.com/cocobrah" target="_blank" rel="noopener noreferrer" alt='Kofi Link'><img src={kofi} alt='Kofi Link' className='kofi-img'/></a>
        </div>
      </header>
      <body>
        {isConnected === 'connected' ? (
          <div className='components'>
            <div className='icons-div'>
              <ViewerCount currentViewerCount={currentViewerCount}/>
              <LikeCount currentLikeCount={currentLikeCount}/>
              <DiamondCount totalDiamonds={totalDiamonds}/>
            </div>
            <div className='messages-div'>
              <div className='social-gift-div'>
                <GiftComponent giftMessages={giftMessages} fontSize={fontSize} />
                <SocialComponent socialMessages={socialMessages} fontSize={fontSize} />
              </div>
              <div className='chat-div'>
                <ChatComponent messages={messages} fontSize={fontSize} />
              </div>
            </div>
          </div>
        ) : isConnected === 'failed' ? (
          <div className='failure'>Room Connection Failure</div>
        ) : (
          <div className='landing-page'>
            <div className='instructions'>
              <h2 className='instructions-title'>Enter Streamer Username:</h2>
              <p className='instructions-p'>Username is shown under display name <br/>You must be live before you can connect</p>
            </div>
            <div className='streamer-input'>
              <input
                type="text"
                placeholder="Enter streamer name"
                value={streamerName}
                onChange={(e) => setStreamerName(e.target.value)
                }
              />
              <button onClick={handleConnect} disabled={!streamerName}>Connect</button>
            </div>
            <div className='loading-div'>
            {loadingStatus ? 
            <div className='animation-div'>
              <Lottie animationData={animationData} className='loading-animation' style={{ width: '150px' }}/>
            </div> :
            <div className='empty-div'>
            </div>
            }
            </div>
          </div>
        )}
      </body>
    </div>
    </div>
  );
}

export default App;
