import React from "react";
import heart from './Assets/heart.svg';
import './css/LikeCount.css';

function LikeCount({ currentLikeCount }) {
    const formattedCount = currentLikeCount.toLocaleString();

    return (
        <div className="like-comp">
            <img src={heart} alt='Likes icon' className="like-icon" />
            <h2 className="like-count">{formattedCount}</h2>
        </div>
    );
};

export default LikeCount;