import React from "react";
import diamond from './Assets/diamond.svg'
import './css/DiamondCount.css';

function DiamondCount({ totalDiamonds }) {
    const formattedCount = totalDiamonds.toLocaleString();

    return (
        <div className="diamond-comp">
            <img src={diamond} alt='Diamonds' className="diamond-icon"/>
            <h2 className="count">{formattedCount}</h2>
        </div>
    );
};

export default DiamondCount;